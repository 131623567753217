import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Control, defaults as defaultControls} from 'ol/control.js';
import Feature from 'ol/Feature.js'
import {Circle} from 'ol/geom.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import Polygon from 'ol/geom/Polygon.js';
import Style from 'ol/style/Style.js';
import Fill from 'ol/style/Fill.js';
import Text from 'ol/style/Text.js';


class RotateNorthControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = 'N';

    const element = document.createElement('div');
    element.className = 'rotate-north ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('mouseover', this.handleRotateNorth.bind(this), false);
  }

  handleRotateNorth() {
    //this.getMap().getView().setRotation(0)
    console.log(this.getMap().getView().getMaxZoom());
  }
}

let coords = [502989.08731627365,6272334.160768232];

let feat = [];

let style = new Style({
  fill: new Fill({color:[0,255,0,0.4]}),
  

});

function nb(val = 1000)
{
  let nb;
  if(Math.floor(Math.random()*10)%2 == 0)
  {
    nb = Math.random()*val
  }
  else
  {
    nb = -Math.random()*val
  };
  return nb;
}

for (let i = 0; i < 500; i++)
{
  
  let x = coords[0]+nb();
  let y = coords[1]+nb();
  let radius = Math.random()*15+5;

  let f = new Feature({geometry: new Circle([x,y], radius)});
  f.setProperties({test:'ok'})

  feat.push(f);
}


let vecteur = new VectorLayer({
  source: new VectorSource({
    features: feat,
  })
})

const map = new Map({
  target: 'map',
  controls: defaultControls().extend([/*new RotateNorthControl()*/]),
  layers: [
    new TileLayer({
      source: new OSM()
    }),
    vecteur
  ],
  view: new View({
    center: coords,
    zoom: 18,
  })
});

let info =document.getElementById('info');

const audioElement = new Audio("snd.mp3");

map.on('pointermove',(e)=>{

  let x = e.coordinate[0];
  let y = e.coordinate[1];
  info.innerHTML = x + ' / ' + y;

  let feats = map.getFeaturesAtPixel(map.getPixelFromCoordinate(e.coordinate));

  if(feats.length != 0){

    let feat = feats[0];
    let ol_uid = feat.ol_uid;
    
   //audioElement.play();
    feat.setStyle(new Style({
      fill: new Fill({color:[0,255,0,0.4]}),
      text: new Text({text:ol_uid})
    
    }));
    
    //feat.getGeometry().setRadius(nb(30)+2);


    console.log(feat)



  }
  
  
})

/* let mouse_over = document.getElementById("map");

mouse_over.addEventListener('mouseover', (event)=>{
  
}) */
